<template>
    <section>
        <div class="temperature__value">{{ (scaleSymbol === 'C')? value : fValue }}</div>
        <div class="temperature__right">
            <div class="temperature__scale">
                <a href="#" @click.prevent="toggleTemperature">&deg;{{ scaleSymbol }}</a>
            </div>
            <!-- <div class="temperature__high">
                <img src="/img/weather/high.svg" alt="high temperature"><span>{{ (scaleSymbol === 'C')? `${high}°` : `${fHigh}°` }}</span>
            </div>
            <div class="temperature__low">
                <img src="/img/weather/low.svg" alt="low temperature"><span>{{ (scaleSymbol === 'C')? `${low}°` : `${fLow}°` }}</span>
            </div> -->
        </div>
    </section>
</template>

<script>
export default {
    name: 'Temperature',

    props: {
        value: {
            type: Number,
            required: true
        },
        high: {
            type: Number,
            required: true
        },
        low: {
            type: Number,
            required: true
        }
    },

    data: function() {
        return {
            scale: 'Celcius'
        }
    },

    computed: {
        scaleSymbol() {
            return this.scale.charAt(0);
        },

        fValue() {
            return this.toFahrenheit(this.value);
        },

        fHigh() {
            return this.toFahrenheit(this.high);
        },

        fLow() {
            return this.toFahrenheit(this.low);
        }
    },

    methods: {
        toFahrenheit(value) {
            return Math.floor((value * 1.8) + 32);
        },

        toggleTemperature() {
            (this.scale === 'Celcius')? this.scale = 'Fahrenheit' : this.scale = 'Celcius';
        }
    }
}
</script>

<style scoped>
section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin: 4px 0; */
}

.temperature__value {
    font-size: 3em;
    color: rgba(255, 255, 255, 0.75);
}

.temperature__right {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    font-size: 10px;
    color: #fff;
}

.temperature__scale {
    padding-top: 5px;
    font-size: 2.5em;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.75);
}
.temperature__scale a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
}

.temperature__high {
    padding-top: 5px;
}

.temperature__high img {
    vertical-align: middle;
    /* width: 10px; */
    height: 1.5em;
}

.temperature__low img {
    vertical-align: middle;
    /* width: 10px; */
    height: 1.5em;
}
.temperature__high span,
.temperature__low span {
    padding-top: 3px;
}
</style>
