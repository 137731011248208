<template>
    <div style="width: 100%; height: 100%">
      <apexchart :width="Width" :height="Height" :type="isDonut?'donut':'pie'" :options="chartOptions" :series="Series"></apexchart>
    </div>
 </template>
 <script>
function chartOptions(){
		return {
			chart: {
					type: this.isDonut?'donut':'pie',
					animations: {
							enabled: true,
					}
			},
			colors: this.Colors.length!=0 ? this.Colors : undefined,
			stroke: {
				show: false,
			},
			labels: this.Categories,
			legend: {
					show: true,
					position: 'bottom',
      		horizontalAlign: 'center', 
					labels: {
						colors: '#ffffff',
					},
			},
			plotOptions: {
					pie: {
							expandOnClick: true,
							donut: {
									size: '55%'
							},
					},
			},
			dataLabels: {
					enabled: true,
					dropShadow: {
							enabled: false,
					},
			},
		}
}

export default {
	name:'simple-donut-or-pie',
	props: {
			Series: {
					type: Array,
					required: true,
					default: () => [],
			},
			Categories:{
					type: Array,
					required: true,
					default: () => [],
			},
			Colors:{
				type: Array,
				required: false,
				default: () => [],
		},
		isDonut:{
				type: Boolean,
				required: false,
				default: true,
		},
		Width:{
				type: String,
				required: false,
				default: '100%',
		},
		Height:{
				type: String,
				required: false,
				default: '280px',
		},
	},
	methods:{

	},
	computed:{
			chartOptions
	},
}
</script>