<template>
  <div style="height: 100%" v-if="isBerthSelected">
    <div class="map-info">
      <div class="close-modal" @click="$emit('CloseModal')">
        <CIcon name="x" />
      </div>

      <div class="map-info-left" v-if="isBerthSelected">
        <h3 class="vessel-title">
          {{ selected.VisitJson[0].VesselName }}
        </h3>
        <p class="vessel-subtitle">
          {{ selected.VisitJson[0].CountryName }} - {{ selected.VisitJson[0].TpVesselName }} {{ selected.VisitJson[0].Imo }}
        </p>
        <p class="vessel-subtitle">
          ATA: {{ formatDate(selected.VisitJson[0].Ata) }} - ETD: {{ formatDate(selected.VisitJson[0].Etd) }}
        </p>
        <p class="vessel-subtitle">
          <span
            class="vessel-status"
            :style="{
              background: getStatusData(selected.VisitJson[0]).Color,
            }"
          ></span>
          {{
            `${$t("label.status")}: ${
              getStatusData(selected.VisitJson[0]).Label
            }`
          }}
        </p>
        <img
          :src="hasVesselImg(selected.VisitJson[0].VesselRoute)"
          alt="VESSEL"
          class="vessel-img"
        />
        <table class="vessel-table three-columns">
          <tbody>
            <tr>
              <td>LWL: {{ formatValue(selected.VisitJson[0].Lwl) }}</td>
              <td>GROSS: {{ formatValue(selected.VisitJson[0].Gross) }} (TON)</td>
              <td>{{$t('label.CProa')}}: {{ formatValue(selected.VisitJson[0].DraftBow) }}</td>
            </tr>
            <tr>
              <td>LOA: {{ formatValue(selected.VisitJson[0].Loa) }}</td>
              <td>TEUS: {{ formatValue(selected.VisitJson[0].Teus) }}</td>
              <td>{{$t('label.CPopa')}}: {{ formatValue(selected.VisitJson[0].DraftStern) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="tables-info">
          <table class="pair-table thirty-percent">
            <thead>
              <tr>
                <th colspan="2">{{$t('label.voyage')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{$t('label.arrival')}}</td>
                <td>{{ selected.VisitJson[0].VoyageArrival }}</td>
              </tr>
              <tr>
                <td>{{$t('label.departure')}}</td>
                <td>{{ selected.VisitJson[0].VoyageDeparture }}</td>
              </tr>
              <tr>
                <td>{{$t('label.iniOps')}}</td>
                <td>{{ formatDate(selected.VisitJson[0].BeginOperationsDate) }}</td>
              </tr>
            </tbody>
          </table>
          <table class="pair-table times">
            <thead>
              <tr>
                <th colspan="2">{{$t('label.times')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="time in selected.VisitJson[0].TimeJson"
                :key="time.LabelEn"
              >
                <td>{{ getDataByLocale(time, "Label") }}</td>
                <td>{{ time.HourDs }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="map-info-right" v-if="isBerthSelected">
        <!-- Container -->
        <div v-if="isContainerShip">
          <div
            class="tables-info mt-0"
          >
            <table class="pair-table">
              <thead>
                <tr>
                  <th colspan="2">{{$t('label.planned')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('label.DISCHARGE')}}</td>
                  <td>
                    {{formatValue(selected.VisitJson[0].ContainerJson[0].CantDischargePlanif)}}
                  </td>
                </tr>
                <tr>
                  <td>{{$t('label.shipment')}}</td>
                  <td>
                    {{formatValue(selected.VisitJson[0].ContainerJson[0].CantLoadPlanif)}}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="pair-table">
              <thead>
                <tr>
                  <th colspan="2">{{$t('label.executedPortSituation')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('label.DISCHARGE')}}</td>
                  <td>
                    {{formatValue(selected.VisitJson[0].ContainerJson[0].CantDischargeVisit)}}
                  </td>
                </tr>
                <tr>
                  <td>{{$t('label.shipment')}}</td>
                  <td>
                    {{formatValue(selected.VisitJson[0].ContainerJson[0].CantLoadVisit)}}
                  </td>
                </tr>
                <tr>
                  <td>{{$t('label.restowPortSituation')}}</td>
                  <td>
                    {{formatValue(selected.VisitJson[0].ContainerJson[0].CantRestowVisit)}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="tables-info mt-0"
          >
            <table class="pair-table other-terminals">
              <thead>
                <tr>
                  <th colspan="2">{{$t("label.otherTerminals")}}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(other, indexO) in splitValues(selected.VisitJson[0].OtherTerminalJson, 3)"
                  :key="'other'+indexO"
                >
                  <td
                    v-for="item in other"
                  :key="item.PortCode"
                  >
                    {{ item.PortCode }}: {{ item.Cant }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <table
            class="vessel-table"
          >
            <thead>
              <tr>
                <th colspan="2">{{$t('label.Movements')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="
                  selected.VisitJson[0].ContainerMovementJson.length != 0
                "
              >
                <td>
                  {{$t('label.DISCHARGE')}}:
                  {{formatValue(selected.VisitJson[0].ContainerMovementJson[0].Discharge)}}
                </td>
                <td>
                  {{$t('label.shipment')}}:
                  {{formatValue(selected.VisitJson[0].ContainerMovementJson[0].Load)}}
                </td>
              </tr>
              <tr
                v-if="
                  selected.VisitJson[0].ContainerMovementJson.length != 0
                "
              >
                <td>
                  {{$t('label.abrevSLS')}}:
                  {{formatValue(selected.VisitJson[0].ContainerMovementJson[0].RestowShipLandShip)}}
                </td>
                <td>
                  {{$t('label.hatch')}}:
                  {{formatValue(selected.VisitJson[0].ContainerMovementJson[0].HatchCover)}}
                </td>
              </tr>
              <tr
                v-if="
                  selected.VisitJson[0].ContainerMovementJson.length != 0
                "
              >
                <td>
                  {{$t('label.BTB')}}:
                  {{formatValue(selected.VisitJson[0].ContainerMovementJson[0].RestowShipShip)}}
                </td>
                <td>
                  {{$t('label.twinBoxes')}}:
                  {{formatValue(selected.VisitJson[0].ContainerMovementJson[0].TwinBox)}}
                </td>
              </tr>
              <tr
                v-if="
                  selected.VisitJson[0].ContainerMovementJson.length != 0
                "
              >
                <td>
                  <strong>
                    MOV X {{$t('label.hour')}}:
                    {{formatValue(selected.VisitJson[0].ContainerMovementJson[0].MovXHour)}}
                  </strong>
                </td>
                <td>
                  {{$t('label.ultMov')}}:
                  {{formatDate(selected.VisitJson[0].ContainerMovementJson[0].LastMovement)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- BulkCarrier -->
        <div v-if="isBulkCarrier">
          <table
            class="vessel-table mt-0"
          >
            <thead>
              <tr>
                <th colspan="2">{{$t('label.Gamela')+'S'}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="selected.VisitJson[0].ImplementJson.length != 0">
                <td>
                  {{$t('label.Dispached')}}:
                  {{formatValue(selected.VisitJson[0].ImplementJson[0].ImplementDispatched)}}
                </td>
                <td>
                  {{$t('label.onCircuit')}}:
                  {{formatValue(selected.VisitJson[0].ImplementJson[0].VehiclesInCircuit)}}
                </td>
              </tr>
              <tr v-if="selected.VisitJson[0].ImplementJson.length != 0">
                <td>
                  {{$t('label.Loadeds')}}:
                  {{formatValue(selected.VisitJson[0].ImplementJson[0].ImplementEmbarked)}}
                </td>
                <td>
                  {{$t('label.Returned')}}:
                  {{formatValue(selected.VisitJson[0].ImplementJson[0].ImplementReturned)}}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="vessel-table"
          >
            <thead>
              <tr>
                <th colspan="2">{{$t('label.movements')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-if="
                  selected.VisitJson[0].ImplementMovementJson.length != 0
                "
              >
                <td>
                  {{$t('label.Bin')}}: 
                  {{formatValue(selected.VisitJson[0].ImplementMovementJson[0].CantImplement)}}
                </td>
                <td>
                  {{$t('label.Gamela')}} X {{$t('label.hour')}}:
                  {{formatValue(selected.VisitJson[0].ImplementMovementJson[0].CantImplementXHour)}}
                </td>
              </tr>
              <tr
                v-if="
                  selected.VisitJson[0].ImplementMovementJson.length != 0
                "
              >
                <td>
                  {{$t('label.hatch')}}: 
                  {{formatValue(selected.VisitJson[0].HatchCoverJson[0].HatchCoverMov)}}
                </td>
                <td>
                  TON X {{$t('label.hour')}}:
                  {{formatValue(selected.VisitJson[0].ImplementMovementJson[0].TonImplementXHour)}}
                </td>
              </tr>
              <tr
                v-if="
                  selected.VisitJson[0].ImplementMovementJson.length != 0
                "
              >
                <td>
                  {{$t('label.lastRead')}}:
                  {{
                    selected.VisitJson[0].ImplementMovementJson[0].LastReadDate ? formatDate(
                      selected.VisitJson[0].ImplementMovementJson[0]
                        .LastReadDate
                    ) : ''
                  }}
                </td>
                <td>
                  {{$t('label.tonLastRead')}}:
                  {{
                    formatValue(
                      selected.VisitJson[0].ImplementMovementJson[0]
                        .LastReadTon
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>     
        </div>
        <!-- GeneralCargo -->
        <div v-if="isGeneralCargo">
          <div class="tables-info mt-0">
            <table class="pair-table">
              <thead>
                <tr>
                  <th colspan="2">{{$t('label.planned')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('label.DISCHARGE')}}</td>
                  <td>
                    {{ formatValue(selected?.VisitJson[0].GeneralCargoJson[0]?.CantDischargePlanif) }}
                  </td>
                </tr>
                <tr>
                  <td>{{$t('label.shipment')}}</td>
                  <td>
                    {{ formatValue(selected?.VisitJson[0]?.GeneralCargoJson[0]?.CantLoadPlanif) }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="pair-table">
              <thead>
                <tr>
                  <th colspan="2">{{$t('label.executed')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('label.DISCHARGE')}}</td>
                  <td>
                    {{ formatValue(selected?.VisitJson[0]?.GeneralCargoJson[0]?.CantDischargeVisit) }}
                  </td>
                </tr>
                <tr>
                  <td>{{$t('label.shipment')}}</td>
                  <td>
                    {{ formatValue(selected?.VisitJson[0]?.GeneralCargoJson[0]?.CantLoadVisit) }}
                  </td>
                </tr>
                <tr>
                  <td>{{$t('label.restowPortSituation')}}</td>
                  <td>
                    {{ formatValue(selected?.VisitJson[0]?.GeneralCargoJson[0]?.CantRestowVisit) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <table class="packaging-table">
            <thead>
              <tr>
                <th style="width: 36% !important">{{$t('label.packaging')}}</th>
                <th style="width: 22% !important">{{$t('label.planned')}}</th>
                <th style="width: 20% !important">{{$t('label.executed')}}</th>
                <th class="text-center" style="width: 22% !important">{{ $t('label.Completed') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="GeneralCargoPackaging in selected?.VisitJson[0]?.GeneralCargoPackagingJson">
                <td>{{ PackagingName(GeneralCargoPackaging) }}</td>
                <td>{{ formatValue(GeneralCargoPackaging?.QuantityPlanning) }}</td>
                <td>{{ formatValue(GeneralCargoPackaging?.QuantityVisit) }}</td>
                <td class="text-center">{{ `${PackagingPercentage(GeneralCargoPackaging)}%` }}</td>
              </tr>
            </tbody>
          </table>
          <table class="vessel-table">
            <thead>
              <tr>
                <th colspan="2">{{$t('label.Movements')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{$t('label.DISCHARGE')}}:
                  {{ formatValue(selected?.VisitJson[0]?.GeneralCargoMovementJson[0]?.Discharge) }}
                </td>
                <td>
                  {{$t('label.shipment')}}:
                  {{ formatValue(selected?.VisitJson[0]?.GeneralCargoMovementJson[0]?.Load) }}
                </td>
              </tr>
              <tr>
                <td>
                  {{$t('label.abrevSLS')}}:
                  {{ formatValue(selected?.VisitJson[0]?.GeneralCargoMovementJson[0]?.RestowShipLandShip) }}
                </td>
                <td>
                  {{$t('label.hatch')}}:
                  {{ formatValue(selected?.VisitJson[0]?.GeneralCargoMovementJson[0]?.HatchCover) }}
                </td>
              </tr>
              <tr>
                <td>
                  {{$t('label.BTB')}}:
                  {{ formatValue(selected?.VisitJson[0]?.GeneralCargoMovementJson[0]?.RestowShipShip) }}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>
                    MOV X {{$t('label.hour')}}:
                    {{ formatValue(selected?.VisitJson[0]?.GeneralCargoMovementJson[0]?.MovXHour) }}
                  </strong>
                </td>
                <td>
                  {{$t('label.ultMov')}}:
                  {{ formatDate(selected?.VisitJson[0]?.GeneralCargoMovementJson[0]?.LastMovement) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- ----------------- -->
        <div class="tables-info">
          <table class="pair-table times delay">
            <thead>
              <tr>
                <th>{{$t('label.delays')}}</th>
                <th class="text-center">HH:MM</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(delay, j) in selected.VisitJson[0].DelayJson"
                :key="delay.TpDelayNameEn"
              >
                <td> 
                  <span class="delay-color mr-1" :class="getDelayColors(j)"></span>
                  {{ getDelayLabel(j) }}
                </td>
                <td>{{ delay.HourDs }}</td>
              </tr>
            </tbody>
          </table>
          <div class="grafica" v-if="graph">
            <GraficaNew
              v-if="hasDelays(selected.VisitJson[0].DelayJson)"
              width='100%'
              height='120px'
              :colors='chartColors()'
              :labels='chartLabels(selected.VisitJson[0].DelayJson)'
              :series='chartSeries(selected.VisitJson[0].DelayJson)'
            ></GraficaNew>
            <div class="card-body d-flex align-items-center justify-content-center no-delays" v-else>
              <h6 style="color:#F67272;text-align:center;" class="estandar-letra">
                {{$t('label.noDelays')}}
              </h6>
            </div>
          </div>
        </div>
        <div v-if="isBulkCarrier">
          <table
            class="vessel-table"
          >
            <thead>
              <tr>
                <th colspan="2">{{$t('label.crane')}}S ({{$t('label.Gamela')+'S'}})</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(crane, indexO) in splitValues(selected.VisitJson[0].CraneImplementJson, 2)"
                :key="'crane'+indexO"
              >
                <td
                  v-for="item in crane"
                :key="item.VesselCraneId"
                >
                  {{ item.CraneAlias }} ({{ item.CantImplement }})
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="vessel-table"
          >
            <thead>
              <tr>
                <th colspan="2">{{$t('label.hold')}}S ({{$t('label.Gamela')+'S'}})</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(hold, indexO) in splitValues(selected.VisitJson[0].HoldImplementJson, 2)"
                :key="'hold'+indexO"
              >
                <td
                  v-for="item in hold"
                :key="item.VesselHoldId"
                >
                  {{ item.VesselHoldName }} ({{ item.CantImplement }})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div style="position: relative; min-height: 200px; border-top: 1px solid #516076;">
      <div class="container-arrow-carousel arrows-carousel-left" v-if="minCarrusel">
        <a
          href="#" 
          @click.prevent="DecreaseCarousel"
          class="arrow left"
        ></a>
      </div>
      <div class="flex-grow-1">
        <CCollapse 
          v-if="isBerthSelected && cleanPositionJson(selected?.VisitJson).length != 0" 
          :show="CarruselTab==1"
          class="p-arrows-carousel text-center"
        >
          <strong class="mb-2">{{ $t('label.Import') }}</strong>
          <div class="vessel-transversal">
            <TransversalViewSmall
              :vesselType="selected.VisitJson[0].TpVesselMasterId"
              :TpVesselId="selected.VisitJson[0].TpVesselId"
              :positionJson="cleanPositionJson(selected?.VisitJson)"
              legend
            />
          </div>
        </CCollapse>
        <CCollapse 
          v-if="isBerthSelected && selected.VisitJson[0].PositionExportJson.length != 0"
          :show="CarruselTab==(cleanPositionJson(selected?.VisitJson).length != 0 ? 2 : 1)"
          class="p-arrows-carousel text-center"
        >
          <strong class="mb-2">{{ $t('label.Export') }}</strong>
          <div class="vessel-transversal">
            <TransversalViewSmall
              :vesselType="selected.VisitJson[0].TpVesselMasterId"
              :TpVesselId="selected.VisitJson[0].TpVesselId"
              :positionJson="selected.VisitJson[0].PositionExportJson"
              legend
            />
          </div>
        </CCollapse>
        <CCollapse 
          v-if="selected?.VisitJson[0]?.GeneralCargoDeckJson && selected?.VisitJson[0]?.GeneralCargoDeckJson?.length != 0"
          :show="CarruselTab==(cleanPositionJson(selected?.VisitJson)?.length == 0 ? (selected?.VisitJson[0]?.PositionExportJson?.length == 0 ? 1 : 2) : (selected?.VisitJson[0]?.PositionExportJson?.length == 0 ? 2 : 3))"
          class="p-arrows-carousel"
        >
          <DeckTable
            :GeneralCargoDeckJson="selected?.VisitJson[0]?.GeneralCargoDeckJson"
          />
        </CCollapse>
      </div>
      <div class="container-arrow-carousel arrows-carousel-right" v-if="maxCarrusel">
        <a
          href="#" 
          @click.prevent="IncreaseCarousel"
          class="arrow right"
        ></a>
      </div>
    </div>
    </div>
</template>
<script>
import { DateFormater } from "@/_helpers/funciones";
import { formatMilDecimal } from '@/_validations/validacionEspeciales';
import TransversalSrc from "@/_mixins/transversalViewSrc";
import GraficaNew from "./grafica-new";
import TransversalViewSmall from "./transversal-view-small";
import DeckTable from "./deck-table";

function data() {
  return {
    CarruselTab: 1,
  }
}

//methods
function formatDate(fecha) {
  return fecha ? DateFormater.formatDateTimeWithSlash(fecha) : "N/A";
}

function getStatusData(info) {
  let uuid = info.VisitStatusId;
  let found = this.status.find((item) => item.ProcessStatusId == uuid);
  if (found)
    return {
      Color: found.ColorStatus,
      Label: this.getDataByLocale(found, "ValueDs"),
    };

  return {
    Color: "",
    Label: "",
  };
}

function hasVesselImg(vesselRoute) {
  if (vesselRoute) {
    return `${this.$store.getters["connection/getBase"]}${
      vesselRoute ? vesselRoute.replace("Public/", "", null, "i") : ""
    }`;
  }else{
    return '';
  }
}

function formatValue(val){
  if(!val || val == 0) return 0;
  return formatMilDecimal(val);
}

function getDataByLocale(item, name) {
  return item[
    name +
      this.$i18n.locale.charAt(0).toUpperCase() +
      this.$i18n.locale.slice(1)
  ];
}

function splitValues(arr, chunk){
  if(arr.length == 0) return [];

  let tempArray = [];
   for (let i=0; i < arr.length; i += chunk) {
    tempArray.push(arr.slice(i, i + chunk));
  }
  return tempArray;
}

function getDelayColors(index){
  switch (index) {
    case 0:
      return 'bg-vessel';
    case 1:
      return 'bg-operator';
    case 2:
      return 'bg-port';
    case 3:
      return 'bg-others';
    default:
      return '';
  }
}

function getDelayLabel(index){
  switch (index) {
    case 0:
      return this.$t('label.vessel');
    case 1:
      return this.$t('label.operator');
    case 2:
      return this.$t('label.port');
    case 3:
      return this.$t('label.others');
    default:
      return '';
  }
}

function hasDelays(arr){
  let delay = 0;
  for (let index = 0; index < arr.length; index++) {
    delay += arr[index].Minutes;
  }
  return delay != 0;
}

function chartColors(){
  let data = ['#ffc000', '#f79646', '#31859b', '#92d050'];
  return data;
}

function chartLabels(arr){
  let data = [];
  arr.map(async (item) => {
    if(this.$i18n.locale == 'es'){
        data.push(item.TpDelayNameEs)
    }else{
        data.push(item.TpDelayNameEn)
    } 
  });
  return data;
}

function chartSeries(arr){  
  let data = [];
  arr.map(async (item) => {
      data.push(item.Minutes)
  });
  return data;
}

function cleanPositionJson(visit){
  if(this.isContainerShip)
    return visit[0].PositionJson;
  
  if(this.isBulkCarrier || this.isGeneralCargo){
    return visit[0].PositionJson.map((item) => Object.assign({}, {
      Position: item.Position,
      PositionJson: item.PositionJson,
      FgBay: item.FgBay,
      FgCrane: item.FgCrane,
      FgHold: item.FgHold
    }));
  }

  return [];
}

function PackagingName(Packaging) {
  if (Packaging) {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    return Packaging['PackagingName'+_lang] ?? '';
  }else{
    return '';
  }
}

function PackagingPercentage(Packaging) {
  let Planned = Packaging?.QuantityPlanning ?? 0;
  let executed = Packaging?.QuantityVisit ?? 0;
  if ((Planned+executed) != 0) {
    return Math.floor((executed/Planned)*100);
  }else{
    return 0;
  }
}

function DecreaseCarousel(){
  if(this.CarruselTab !== 1){
    this.CarruselTab = this.CarruselTab - 1;
  }
}

function IncreaseCarousel(){
  if(this.CarruselTab < 2){
    this.CarruselTab = this.CarruselTab + 1;
  }
}

//computed
function minCarrusel(){
  return this.CarruselTab > 1;
}

function maxCarrusel(){
  if (this.selected?.VisitJson[0]?.GeneralCargoDeckJson?.length != 0) {
    return this.CarruselTab < 2;
  }else{
    return this.CarruselTab < 1;
  }
}

export default {
  name: "map-modal",
  mixins: [TransversalSrc],
  data,
  props: {
    selected: null,
    graph: Boolean,
    fullscreen: Boolean,
    isContainerShip: Boolean,
    isBulkCarrier: Boolean,
    isBerthSelected: Boolean,
    isGeneralCargo: Boolean,
    status: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    GraficaNew,
    TransversalViewSmall,
    DeckTable,
  },
  methods: {
    formatDate,
    getStatusData,
    hasVesselImg,
    formatValue,
    getDataByLocale,
    splitValues,
    getDelayColors,
    getDelayLabel,
    hasDelays,
    chartColors,
    chartLabels,
    chartSeries,
    cleanPositionJson,
    PackagingName,
    PackagingPercentage,
    DecreaseCarousel,
    IncreaseCarousel,
  },
  computed: {
    minCarrusel,
    maxCarrusel,
  },
  watch: {
    selected: function (NewVal) {
      this.CarruselTab = 1;
    }
  }
};
</script>
<style scoped>
.map-info {
  width: 100%;
  height: 60%;

  display: flex;
  position: relative;
}
.map-info-left,
.map-info-right {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 5px;
  overflow-y: scroll;
}
.map-info-right .subtitle {
  font-size: 10px;
  font-weight: bold;
  padding-left: 5px;
}
.map-info-right{
  overflow-x: hidden;
}
.map-info-left {
  border-right: 1px solid #516076;
}
.vessel-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #2d4766 !important;
  margin-bottom: 0;
}
.vessel-subtitle {
  font-weight: bold;
  margin-bottom: 0;
  font-size: 0.7rem;
}
.vessel-status,
.delay-color {
  height: 9px;
  width: 9px;
  background: #666666;
  display: inline-block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.delay-color {
  width: 14px;
  height: 14px;
}
.vessel-img {
  height: 50% !important;
  min-width: 1px !important;
  margin: 0 auto;
}

.packaging-table{
  font-size: 10px;
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

table.packaging-table td,
table.packaging-table th {
  padding: 0 5px;
}
.vessel-table {
  font-size: 10px;
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
table.vessel-table td,
table.vessel-table th {
  width: 50%;
}
table.vessel-table td,
table.vessel-table th {
  padding: 0 5px;
}

table.vessel-table.three-columns td {
  width: 30%;
}
.tables-info {
  margin-top: 10px;
  display: flex;
}
.pair-table {
  font-size: 10px;
  width: 50%;
  border-collapse: collapse;
  height: 1px;
}
table.pair-table td,
table.pair-table th {
  padding: 0 5px;
}
table.pair-table td:first-child {
  width: 25%;
}
table.pair-table.thirty-percent td:first-child {
  width: 30%;
}
table.pair-table.other-terminals td{
  width: 33.33%;
}
table.pair-table.other-terminals{
  width: 100%;
}
table.pair-table.times td:first-child {
  width: 45%;
}
.delay tr td:nth-child(2) {
  text-align: center;
}
.delay tr td:first-child {
  padding: 5px;
  width: 100% !important;
  display: flex;
}
.grafica {
  width: 70%;
  /* overflow: hidden; */
}
.no-delays{
  height: 100%;
}
.estandar-letra{
  font-size: 15px !important;
  font-weight: 100 !important;
}
.vessel-transversal {
  width: 100%;
  height: 40%;
  /* background-color: darkcyan; */
}

.vessel-transversal {
  width: 100%;
  height: 40%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.vessel-transversal .titulo {
  font-size: 1.3rem;
  font-weight: bold;
  align-self: flex-end;
  margin: 5px 20px 20px 0;
}
.vessel-transversal .vista {
  width: 750px;
  height: 200px;
  position: relative;
  overflow: hidden;
}
.vessel-transversal .vista img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.vessel-transversal .vista .top-half {
  /*IMPORTANT*/
  position: absolute;
  top: 0;
  bottom: 32%;
  /* left: 22%;
  right: 12%; */
  left: 25%;
  right: 15%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.vessel-transversal .vista .bottom-half {
  /*IMPORTANT*/
  position: absolute;
  top: 68%;
  bottom: 2%;
  /* left: 22%;
  right: 12%; */
  left: 25%;
  right: 15%;
  display: flex;
  flex-direction: column;
}
.close-modal {
  position: absolute;
  right: 2%;
  top: 0%;
  cursor: pointer;
}
.close-modal > svg {
  min-width: 1px !important;
}
.bg-vessel {
  background-color: #ffc000;
}
.bg-operator {
  background-color: #f79646;
}
.bg-port {
  background-color: #31859b;
}
.bg-others {
  background-color: #92d050;
}

.p-arrows-carousel {
  padding: 0.5rem;
}
.arrows-carousel-left {
  position: absolute;
  top: 0;
  left: -1%;
  height: 100%;
}
.arrows-carousel-right {
  position: absolute;
  top: 0;
  right: -1%;
  height: 100%;
}
.container-arrow-carousel {
  width: 32px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-arrow-carousel > .arrow {
	width: 30px;
	height: 30px;
	background: transparent;
	border-top: 5px solid grey;
	border-right: 5px solid grey;
	box-shadow: 0 0 0 lightgray;
	transition: all 200ms ease;
	
	&.left {
		left: 0;
		transform: translate3d(35%,0,0) rotate(-135deg);
	}

	&.right {
		right: 0;
		transform: translate3d(-35%,0,0) rotate(45deg);
	}
	
	&:hover {
		border-color: orange;
		box-shadow: 3px -3px 0 grey;
	}
	
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-40%,-60%) rotate(45deg);
		width: 200%;
		height: 200%;
	}
}
</style>