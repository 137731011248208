<template>
  <div class="content-map" id="content-map">
    <section class="body-map">

      <PortSituationSvg 
        ref="map"
        :selectedYard="selectedYard"
        :berthZoomed="berthZoomed"
        :selected="selected"
        :berths="berths"
        :status="status"
        @zoomToYard="zoomToYard"
        @zoomToBerth="zoomToBerth"
      />

      <div class="sidebar fondo-sidebar">
        <img :src="hasLogo" :alt="getCompanyName" srcset="" class="sidebar-logo" />
        <div class="sidebar-options">
          <div
            class="fullscreen-option"
            @click="fullscreenMap"
            :class="{ active: fullscreen ? true : null }"
          >
            <img
              src="/img/weather/fullscreen-svgrepo-com.svg"
              alt="anchor"
              srcset=""
              class="fullscreen-icon"
            />
            <h4 class="berth-options-title">FULLSCREEN</h4>
          </div>
          <div
            class="fullscreen-option"
            @click="togglePlaying"
          >
            <img
              src="/img/iconos/pause-svgrepo-com.svg"
              alt="anchor"
              srcset=""
              class="fullscreen-icon"
              v-if="isPlaying"
            />
            <img
              src="/img/iconos/play-svgrepo-com.svg"
              alt="anchor"
              srcset=""
              class="fullscreen-icon"
              v-else
            />
          </div>
          <img
            src="/img/weather/anchorRecurso4.svg"
            alt="anchor"
            srcset=""
            class="anchor-icon"
          />
          <h4 class="berth-options-title">{{$t('label.puestoDeAtraque')}}</h4>
          <ul class="berth-options">
            <li
              @click="zoomToBerth('PA-1')"
              :class="{ active: checkBerthZoomed('PA-1') ? true : null }"
            >
              PA-1
            </li>
            <li
              @click="zoomToBerth('PA-2')"
              :class="{ active: checkBerthZoomed('PA-2') ? true : null }"
            >
              PA-2
            </li>
            <li
              @click="zoomToBerth('PA-3')"
              :class="{ active: checkBerthZoomed('PA-3') ? true : null }"
            >
              PA-3
            </li>
            <li
              @click="zoomToBerth('PA-4')"
              :class="{ active: checkBerthZoomed('PA-4') ? true : null }"
            >
              PA-4
            </li>
            <li
              @click="zoomToBerth('PA-5')"
              :class="{ active: checkBerthZoomed('PA-5') ? true : null }"
            >
              PA-5
            </li>
            <li
              @click="zoomToBerth('PA-6')"
              :class="{ active: checkBerthZoomed('PA-6') ? true : null }"
            >
              PA-6
            </li>
            <li
              @click="(FgPortStatics!=1 ? FgPortStatics=1 : FgPortStatics=0, closeModal())"
              :class="{ active: FgPortStatics==1 ? true : null }"
            >
              {{ $t('label.PortSituation') }}
            </li>
          </ul>
        </div>
        <div class="sidebar-weather">
          <WeatherApp :latitude="latitude" :longitude="longitude" />
        </div>
      </div>
      
      <div class="block-statisct">
        <div>
          <div class="sidebar fondo-sidebar" style="left:-150px" v-if="FgPortStatics==2">
            <img :src="hasLogo" :alt="getCompanyName" srcset="" class="sidebar-logo" />
            <div class="sidebar-options">
              <div
                class="fullscreen-option"
                @click="fullscreenMap"
                :class="{ active: fullscreen ? true : null }"
              >
                <img
                  src="/img/weather/fullscreen-svgrepo-com.svg"
                  alt="anchor"
                  srcset=""
                  class="fullscreen-icon"
                />
                <h4 class="berth-options-title">FULLSCREEN</h4>
              </div>
              <div
                class="fullscreen-option"
                @click="togglePlaying"
              >
                <img
                  src="/img/iconos/pause-svgrepo-com.svg"
                  alt="anchor"
                  srcset=""
                  class="fullscreen-icon"
                  v-if="isPlaying"
                />
                <img
                  src="/img/iconos/play-svgrepo-com.svg"
                  alt="anchor"
                  srcset=""
                  class="fullscreen-icon"
                  v-else
                />
              </div>
              <img
                src="/img/weather/anchorRecurso4.svg"
                alt="anchor"
                srcset=""
                class="anchor-icon"
              />
              <h4 class="berth-options-title">{{$t('label.puestoDeAtraque')}}</h4>
              <ul class="berth-options">
                <li
                  @click="zoomToBerth('PA-1')"
                  :class="{ active: checkBerthZoomed('PA-1') ? true : null }"
                >
                  PA-1
                </li>
                <li
                  @click="zoomToBerth('PA-2')"
                  :class="{ active: checkBerthZoomed('PA-2') ? true : null }"
                >
                  PA-2
                </li>
                <li
                  @click="zoomToBerth('PA-3')"
                  :class="{ active: checkBerthZoomed('PA-3') ? true : null }"
                >
                  PA-3
                </li>
                <li
                  @click="zoomToBerth('PA-4')"
                  :class="{ active: checkBerthZoomed('PA-4') ? true : null }"
                >
                  PA-4
                </li>
                <li
                  @click="zoomToBerth('PA-5')"
                  :class="{ active: checkBerthZoomed('PA-5') ? true : null }"
                >
                  PA-5
                </li>
                <li
                  @click="zoomToBerth('PA-6')"
                  :class="{ active: checkBerthZoomed('PA-6') ? true : null }"
                >
                  PA-6
                </li>
                <li
                  @click="(FgPortStatics!=1 ? FgPortStatics=1 : FgPortStatics=0, closeModal())"
                  :class="{ active: FgPortStatics==1 ? true : null }"
                >
                  {{ $t('label.PortSituation') }}
                </li>
              </ul>
            </div>
            <div class="sidebar-weather">
              <WeatherApp :latitude="latitude" :longitude="longitude" />
            </div>
          </div>
          <div ref="YardStatistics" style="display: none;">
            <YardStatistics
              :FgPortStatics="FgPortStatics==2"
              :YardId="YardId"
              @Close="closeModal(), FgPortStatics = 0"
            />
          </div>
        </div>
      </div>

      <div class="block-statisctPort" v-if="FgPortStatics==1">
        <div>
          <div class="sidebar fondo-sidebar" style="left:-150px">
            <img :src="hasLogo" :alt="getCompanyName" srcset="" class="sidebar-logo" />
            <div class="sidebar-options">
              <div
                class="fullscreen-option"
                @click="fullscreenMap"
                :class="{ active: fullscreen ? true : null }"
              >
                <img
                  src="/img/weather/fullscreen-svgrepo-com.svg"
                  alt="anchor"
                  srcset=""
                  class="fullscreen-icon"
                />
                <h4 class="berth-options-title">FULLSCREEN</h4>
              </div>
              <div
                class="fullscreen-option"
                @click="togglePlaying"
              >
                <img
                  src="/img/iconos/pause-svgrepo-com.svg"
                  alt="anchor"
                  srcset=""
                  class="fullscreen-icon"
                  v-if="isPlaying"
                />
                <img
                  src="/img/iconos/play-svgrepo-com.svg"
                  alt="anchor"
                  srcset=""
                  class="fullscreen-icon"
                  v-else
                />
              </div>
              <img
                src="/img/weather/anchorRecurso4.svg"
                alt="anchor"
                srcset=""
                class="anchor-icon"
              />
              <h4 class="berth-options-title">{{$t('label.puestoDeAtraque')}}</h4>
              <ul class="berth-options">
                <li
                  @click="zoomToBerth('PA-1')"
                  :class="{ active: checkBerthZoomed('PA-1') ? true : null }"
                >
                  PA-1
                </li>
                <li
                  @click="zoomToBerth('PA-2')"
                  :class="{ active: checkBerthZoomed('PA-2') ? true : null }"
                >
                  PA-2
                </li>
                <li
                  @click="zoomToBerth('PA-3')"
                  :class="{ active: checkBerthZoomed('PA-3') ? true : null }"
                >
                  PA-3
                </li>
                <li
                  @click="zoomToBerth('PA-4')"
                  :class="{ active: checkBerthZoomed('PA-4') ? true : null }"
                >
                  PA-4
                </li>
                <li
                  @click="zoomToBerth('PA-5')"
                  :class="{ active: checkBerthZoomed('PA-5') ? true : null }"
                >
                  PA-5
                </li>
                <li
                  @click="zoomToBerth('PA-6')"
                  :class="{ active: checkBerthZoomed('PA-6') ? true : null }"
                >
                  PA-6
                </li>
                <li
                  @click="(FgPortStatics!=1 ? FgPortStatics=1 : FgPortStatics=0, closeModal())"
                  :class="{ active: FgPortStatics==1 ? true : null }"
                >
                  {{ $t('label.PortSituation') }}
                </li>
              </ul>
            </div>
            <div class="sidebar-weather">
              <WeatherApp :latitude="latitude" :longitude="longitude" />
            </div>
          </div>
          <PortStatistict/>
        </div> 
      </div>

      <img v-if="FgPortStatics==0" src="/img/piesofticoRecurso.svg" alt="SIGET" class="copyright" />
      
      <div ref="mapModal" class="map-modal">
        <MapModal
          :selected="selected"
          :graph="graph"
          :fullscreen="fullscreen"
          :isBerthSelected="isBerthSelected"
          :isContainerShip="isContainerShip(selected?.VisitJson)"
          :isBulkCarrier="isBulkCarrier(selected?.VisitJson)"
          :isGeneralCargo="isGeneralCargo(selected?.VisitJson)"
          :status="status"
          @CloseModal="closeModal"
        />
      </div>

      <div class="loader-container" v-if="isLoadidngMap">
        <loading-overlay
          :active="isLoadidngMap"
          :is-full-page="true"
          loader="bars"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import gsap from "gsap";
import WeatherApp from "@/components/weather/WeatherApp.vue";
import TransversalSrc from "@/_mixins/transversalViewSrc";
import simpleDonutChart from '@/pages/situacion-operativa-actual/components/simpleDonutChart';
import PortStatistict from "./port-statistict";
import YardStatistics from "./yard-statistics";
import MapModal from "./map-modal";
import PortSituationSvg from "./port-situation-svg";

function data() {
  return {
    YardId: '',
    FgPortStatics: 0,
    selectedYard: '',
    mapInfo: {
      map: true,
      vesselOne: true,
      vesselTwo: true,
      vesselThree: true,
      vesselFour: true,
      vesselFive: true,
      vesselSix: true,
    },
    items: [],
    currentViewBox: "-250 -30 1700.8 600",
    ogViewBox: "-250 -30 1700.8 600",
    fullscreen: false,
    berthZoomed: "",
    latitude: 10.270872,
    longitude: -64.3932861,
    // Map info
    status: [],
    isLoadidngMap: false,
    berths: [
      {
        Nro: 1,
        BerthId: "",
        BerthName: "",
        CircuitLayerName: "pa1",
        LineDock: 0,
        Draft: 0,
        Vita: 0,
        VisitJson: [],
      },
      {
        Nro: 2,
        BerthId: "",
        BerthName: "",
        CircuitLayerName: "pa2",
        LineDock: 0,
        Draft: 0,
        Vita: 0,
        VisitJson: [],
      },
      {
        Nro: 3,
        BerthId: "",
        BerthName: "",
        CircuitLayerName: "pa3",
        LineDock: 0,
        Draft: 0,
        Vita: 0,
        VisitJson: [],
      },
      {
        Nro: 4,
        BerthId: "",
        BerthName: "",
        CircuitLayerName: "pa4",
        LineDock: 0,
        Draft: 0,
        Vita: 0,
        VisitJson: [],
      },
      {
        Nro: 5,
        BerthId: "",
        BerthName: "",
        CircuitLayerName: "pa5",
        LineDock: 0,
        Draft: 0,
        Vita: 0,
        VisitJson: [],
      },
      {
        Nro: 6,
        BerthId: "",
        BerthName: "",
        CircuitLayerName: "pa6",
        LineDock: 0,
        Draft: 0,
        Vita: 0,
        VisitJson: [],
      },
    ],
    selected: null,
    graph: false,
    // Interval
    isPlaying: false,
    timePlaying: 0,
    intervalPlaying: null,
    currentPlaying: '',
    indexPlaying: -1,
    dataTime: 0,
  };
}

function created() {
  this.$store.state.planificacionestiba.isHidden = false;
  this.getMapData();
}
function mounted() {
  this.$nextTick(async () => {
    this.initInverval();
  });
}
// methods
function checkBerth(berth) {
  let found = this.items.findIndex((item) => item.BerthName == berth);

  return found != -1;
}
async function zoomToBerth(berth) {
  if(this.isLoadidngMap) return;
  this.selectedYard = '';
  this.YardId = '';
  this.FgPortStatics = 0;
  let circle = "";

  switch (berth) {
    case "PA-1":
      this.currentViewBox =
        this.currentViewBox == "1020 185 760.8 320"
          ? this.ogViewBox
          : "1020 185 760.8 320";
      if (berth == this.berthZoomed) {
        this.berthZoomed = "";
        this.selected = null;
      } else {
        this.berthZoomed = "PA-1";
        await this.selectBerth("pa1");
        if(!this.isPlaying) this.currentPlaying = 'pa1';
        // if(this.selected && this.selected.VisitJson.length !=0) this.indexPlaying = 0;
      }
      circle = "pa1Circle";
      break;
    case "PA-2":
      this.currentViewBox =
        this.currentViewBox == "938 88 760.8 320"
          ? this.ogViewBox
          : "938 88 760.8 320";
      if (berth == this.berthZoomed) {
        this.berthZoomed = "";
        this.selected = null;
      } else {
        this.berthZoomed = "PA-2";
        await this.selectBerth("pa2");
        if(!this.isPlaying) this.currentPlaying = 'pa2';
        // if(this.selected && this.selected.VisitJson.length !=0) this.indexPlaying = 1;
      }
      circle = "pa2Circle";
      break;
    case "PA-3":
      this.currentViewBox =
        this.currentViewBox == "810 50 760.8 320"
          ? this.ogViewBox
          : "810 50 760.8 320";
      if (berth == this.berthZoomed) {
        this.berthZoomed = "";
        this.selected = null;
      } else {
        this.berthZoomed = "PA-3";
        await this.selectBerth("pa3");
        if(!this.isPlaying) this.currentPlaying = 'pa3';
        // if(this.selected && this.selected.VisitJson.length !=0) this.indexPlaying = 2;
      }
      circle = "pa3Circle";
      break;
    case "PA-4":
      this.currentViewBox =
        this.currentViewBox == "680 16 760.8 320"
          ? this.ogViewBox
          : "680 16 760.8 320";
      if (berth == this.berthZoomed) {
        this.berthZoomed = "";
        this.selected = null;
      } else {
        this.berthZoomed = "PA-4";
        await this.selectBerth("pa4");
        if(!this.isPlaying) this.currentPlaying = 'pa4';
        // if(this.selected && this.selected.VisitJson.length !=0) this.indexPlaying = 3;
      }
      circle = "pa4Circle";
      break;
    case "PA-5":
      this.currentViewBox =
        this.currentViewBox == "550 -10 760.8 320"
          ? this.ogViewBox
          : "550 -10 760.8 320";
      if (berth == this.berthZoomed) {
        this.berthZoomed = "";
        this.selected = null;
      } else {
        this.berthZoomed = "PA-5";
        await this.selectBerth("pa5");
        if(!this.isPlaying) this.currentPlaying = 'pa5';
        // if(this.selected && this.selected.VisitJson.length !=0) this.indexPlaying = 4;
      }
      circle = "pa5Circle";
      break;
    case "PA-6":
      this.currentViewBox =
        this.currentViewBox == "390 -20 760.8 320"
          ? this.ogViewBox
          : "390 -20 760.8 320";
      if (berth == this.berthZoomed) {
        this.berthZoomed = "";
        this.selected = null;
      } else {
        this.berthZoomed = "PA-6";
        await this.selectBerth("pa6");
        if(!this.isPlaying) this.currentPlaying = 'pa6';
        // if(this.selected && this.selected.VisitJson.length !=0) this.indexPlaying = 5;
      }
      circle = "pa6Circle";
      break;
    default:
      this.currentViewBox = "-250 -30 1700.8 600";
      circle = "";
      break;
  }
  if(this.isPlaying) this.timePlaying = 0;

  if (circle) this.circleAnimation(circle);

  this.animationYard();
  this.animationModal();
  this.animationBounce();
}
async function zoomToYard(yard) {
  if(this.isLoadidngMap) return;
  this.FgPortStatics = 2;
  this.berthZoomed = "";
  this.YardId = '';
  this.selected = null;
  let circle = "";

  switch (yard) {
    case "Yard_4":
      this.currentViewBox =
        this.currentViewBox == "800 110 760.8 320"
          ? this.ogViewBox
          : "800 110 760.8 320";
      circle = "Yard4Circle";
      this.selectedYard = this.selectedYard != 'Yard_4' ? 'Yard_4' : '';
      this.YardId = process.env.VUE_APP_YARD4_ID;
      break;
    case "Yard_12":
      this.currentViewBox =
        this.currentViewBox == "310 32 760.8 320"
          ? this.ogViewBox
          : "310 32 760.8 320";
      circle = "Yard12Circle";
      this.selectedYard = this.selectedYard != 'Yard_12' ? 'Yard_12' : '';
      this.YardId = process.env.VUE_APP_YARD12_ID;
      break;
    case "Yard_13":
      this.currentViewBox =
        this.currentViewBox == "310 90 760.8 320"
          ? this.ogViewBox
          : "310 90 760.8 320";
      circle = "Yard13Circle";
      this.selectedYard = this.selectedYard != 'Yard_13' ? 'Yard_13' : '';
      this.YardId = process.env.VUE_APP_YARD13_ID;
      break;
    case "Yard_17":
      this.currentViewBox =
        this.currentViewBox == "250 90 760.8 320"
          ? this.ogViewBox
          : "250 90 760.8 320";
      circle = "Yard17Circle";
      this.selectedYard = this.selectedYard != 'Yard_17' ? 'Yard_17' : '';
      this.YardId = process.env.VUE_APP_YARD17_ID;
      break;
    default:
      this.currentViewBox = "-250 -30 1700.8 600";
      this.selectedYard = '';
      this.YardId = '';
      circle = "";
      break;
  }

  if(this.isPlaying) this.timePlaying = 0;

  if (circle) await this.circleAnimation(circle);

  await this.animationModal();
  await this.animationBounce();
  await this.animationYard();
}
async function selectBerth(berth) {
  let found = this.berths.find((item) => item.CircuitLayerName == berth);
  if (found && found?.VisitJson[0]?.VisitId) {
    this.isLoadidngMap = true;
    await this.$http.get("TerminalSituation-by-VisitId", {VisitId: found?.VisitJson[0]?.VisitId ?? ''})
      .then(response => {
        let VisitJson = response.data.data;
        if (VisitJson.length!=0) {
          this.selected = { ...found, VisitJson: VisitJson[0].VisitJson };
        }
      })
      .catch((err) => {
        this.selected = null;
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isLoadidngMap = false;
      })
  }else{
    this.selected = null;
  }
}
function checkBerthZoomed(berth) {
  return this.berthZoomed == berth;
}
function closeModal() {
  this.selected = null;
  this.currentViewBox = this.ogViewBox;
  this.berthZoomed = "";
  this.selectedYard = '';
  this.YardId = '';
  this.animationModal();
  this.animationBounce();
  this.animationYard();
}

function animationBounce() {
  let current = this.currentViewBox;
  gsap.to(this.$refs.map.$refs.mapSvg, {
    duration: 1,
    attr: { viewBox: current },
    ease: "back.out(1.5)",
  });
}
function circleAnimation(reference) {
  gsap.fromTo(
    this.$refs.map.$refs[reference],
    {
      opacity: 0,
    },
    {
      opacity: 0.19,
      duration: 0.5,
      ease: "power1.inOut",
    }
  );
}
function animationModal() {
  if(this.isBerthSelected){
    gsap.fromTo(this.$refs.mapModal, {
      display: 'none',
      opacity: 0,
      x: 150,
    },{
      display: 'flex',
      opacity: 1,
      x: 0,
      duration: 0.75,
    });
    this.$nextTick(() => {
      this.graph = true;
    });
  }else if(this.graph){
    this.graph = false;
    gsap.to(this.$refs.mapModal, {
      display: 'none',
      opacity: 0,
      x: 150,
      duration: 0.25,
    });
  }
}
function animationYard() {
  if(this.selectedYard){
    gsap.fromTo(this.$refs.YardStatistics, {
      display: 'none',
      opacity: 0,
      x: 0,
    },{
      display: 'flex',
      opacity: 1,
      x: 0,
      duration: 1.5,
    });
  }else {
    gsap.to(this.$refs.YardStatistics, {
      display: 'none',
      opacity: 0,
      x: 0,
      duration: 0.5,
    });
  }
}
function fullscreenMap() {
  this.fullscreen = !this.fullscreen;
  this.$store.state.planificacionestiba.isHidden =
    !this.$store.state.planificacionestiba.isHidden;
  if (!this.fullscreen){
    this.currentViewBox = "-250 -30 1700.8 600";
    this.ogViewBox = "-250 -30 1700.8 600";
  }else{
    this.currentViewBox = "-50 -30 1400.8 600";
    this.ogViewBox = "-50 -30 1400.8 600";
  }
  if (!this.isBerthSelected) {
    this.animationBounce();
  }
}
function getMapData() {
  this.isLoadidngMap = true;
  this.$http
    .get("TerminalSituationList-by-CompanyBranchId", {
      CompanyBranchId: this.getBranchId,
    })
    .then((response) => {
      if(this.selected)
        this.closeModal();
      this.timePlaying = 0;
      this.currentPlaying = '';
      this.indexPlaying = -1;
      this.dataTime = 0;

      this.status = response.data.data[0].VisitStatusJson;
      this.setBerths(response.data.data[0].BerthJson);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
      this.dataTime = 440;
    })
    .then(() => {
      this.isLoadidngMap = false;
    });
}
function setBerths(response) {
  for (let index = 0; index < this.berths.length; index++) {
    let found = response.find(
      (item) => item.CircuitLayerName == this.berths[index].CircuitLayerName
    );
    if (found) this.berths[index] = { ...this.berths[index], ...found };
  }
}

function isContainerShip(item) {
  let TpVesselId = item && item[0]?.TpVesselId ? item[0]?.TpVesselId : '';
  return TpVesselId == process.env.VUE_APP_CONTAINER_SHIP_VESSEL_ID;
}

function isBulkCarrier(item) {
  let TpVesselId = item && item[0]?.TpVesselId ? item[0]?.TpVesselId : '';
  return TpVesselId == process.env.VUE_APP_BULK_VESSEL_ID;
}

function isGeneralCargo(item) {
  let TpVesselId = item && item[0]?.TpVesselId ? item[0]?.TpVesselId : '';
  return TpVesselId == process.env.VUE_APP_GENERAL_CARGO_VESSEL_ID;
}

function chartLegends(){
  return {
      show: true,
  }
}

function togglePlaying() {
  if(this.isLoadidngMap) return;

  this.isPlaying = !this.isPlaying;
  if(this.isPlaying && this.selected && this.selected?.CircuitLayerName != this.currentPlaying)
    this.timePlaying = 0;

  if(!this.isPlaying && !this.selected)
    this.indexPlaying = -1;
}
function initInverval(){
  this.intervalPlaying = setInterval(() => {
    if(this.isPlaying && !this.isLoadidngMap) {
      this.timePlaying ++;

      if(this.timePlaying == 120 && this.selected) {
        let berthSelected = this.selected?.CircuitLayerName;
        switch (berthSelected) {
          case 'pa1':
            this.zoomToBerth('PA-1');
            break;
          case 'pa2':
            this.zoomToBerth('PA-2');
            break;
          case 'pa3':
            this.zoomToBerth('PA-3');
            break;
          case 'pa4':
            this.zoomToBerth('PA-4');
            break;
          case 'pa5':
            this.zoomToBerth('PA-5');
            break;
          case 'pa6':
            this.zoomToBerth('PA-6');
            break;
          default:
            break;
        }
        this.currentPlaying = '';
      }
      if(this.timePlaying == 10 && !this.selected) {
        let qty = this.currentVessels.length;

        this.indexPlaying = (this.indexPlaying + 1) >= qty ? 0 : this.indexPlaying + 1;

        let berthSelected = this.currentVessels[this.indexPlaying].CircuitLayerName;
        switch (berthSelected) {
          case 'pa1':
            case 'pa1':
            this.zoomToBerth('PA-1');
            break;
          case 'pa2':
            this.zoomToBerth('PA-2');
            break;
          case 'pa3':
            this.zoomToBerth('PA-3');
            break;
          case 'pa4':
            this.zoomToBerth('PA-4');
            break;
          case 'pa5':
            this.zoomToBerth('PA-5');
            break;
          case 'pa6':
            this.zoomToBerth('PA-6');
            break;
          default:
            break;
        }
      }
    }

    this.dataTime ++;
    if(this.dataTime == 500){
      this.getMapData();
    }
  }, 1000);
}

// computed
function getSwitches() {
  return Object.keys(this.mapInfo);
}

function hasLogo() {
  return `${this.$store.getters["connection/getBase"]}${
    this.user.LogoRouteI
      ? this.user.LogoRouteI.replace("Public/", "", null, "i")
      : ""
  }`;
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}
function isBerthSelected() {
  if (!this.selected) return false;

  if (this.selected?.VisitJson.length == 0) return false;
  else return true;
}
function currentVessels() {
  return this.berths.filter((item) => item?.VisitJson.length != 0);
}
function getCompanyName() {
  return this.user ? this.user.CompanyName : "";
}

export default {
  name: "sitacion-puerto",
  mixins: [TransversalSrc],
  components: {
    WeatherApp,
    simpleDonutChart,
    PortStatistict,
    YardStatistics,
    MapModal,
    PortSituationSvg,
  },
  created,
  mounted,
  data,
  methods: {
    checkBerth,
    zoomToBerth,
    zoomToYard,
    animationBounce,
    animationYard,
    fullscreenMap,
    checkBerthZoomed,
    circleAnimation,
    getMapData,
    setBerths,
    isContainerShip,
    isBulkCarrier,
    isGeneralCargo,
    selectBerth,
    closeModal,
    animationModal,
    togglePlaying,
    initInverval,
  },
  computed: {
    getSwitches,
    hasLogo,
    getBranchId,
    isBerthSelected,
    chartLegends,
    currentVessels,
    getCompanyName,
    ...mapState({
      user: (state) => state.auth.user,
      branch: (state) => state.auth.branch,
    }),
  },
  beforeDestroy(){
    clearInterval(this.intervalPlaying);
  },
};
</script>
<style scoped>

/* Main */
.content-map {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.body-map {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  position: absolute;
  transition: height 0.2s ease;
}
.body-map .sidebar {
  background-color: #717e8e;
  min-width: 150px;
  width: 150px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.body-map .map {
  position: relative;
  width: 100%;
}
.body-map .sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
.body-map .sidebar button {
  padding: 5px 20px;
}
.fondo-sidebar {
  background: #4c5566 url("/img/weather/sidebar.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.sidebar-logo {
  width: 100%;
}
.sidebar-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidebar-weather {
  width: 100%;
  margin-top: auto;
}
.berth-options {
  list-style: none;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  padding: 0;
  margin: 0;
}
.berth-options > li {
  cursor: pointer;
  color: #fff;
  transition: color 0.5s ease;
}
.berth-options > li:last-child {
  padding-bottom: 5px;
}
.berth-options > li:hover,
.berth-options > li.active {
  color: #eba784;
}
.berth-options-title {
  color: #fff;
  text-align: center;
  font-size: 1rem;
}
.anchor-icon {
  width: 22px;
  margin: 6px;
  filter: invert(100%) sepia(0%) saturate(7476%) hue-rotate(110deg)
    brightness(98%) contrast(108%);
}
.fullscreen-icon {
  width: 22px;
  margin: 6px;
  filter: invert(100%) sepia(0%) saturate(7476%) hue-rotate(110deg)
    brightness(98%) contrast(108%);
  transition: filter 0.5s ease;
}
.fullscreen-option {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fullscreen-option > h4 {
  color: #fff;
  transition: color 0.5s ease;
}
.fullscreen-option:hover > img,
.fullscreen-option.active > img {
  filter: invert(74%) sepia(6%) saturate(3297%) hue-rotate(328deg)
    brightness(104%) contrast(84%);
}
.fullscreen-option:hover > h4,
.fullscreen-option.active > h4 {
  color: #eba784;
}
.copyright {
  position: absolute;
  bottom: 1%;
  right: 1%;
  width: 10%;
  z-index: 1;
}
.block-statisct{
  left: 150px;
  position: absolute;
  z-index: 2;
}
.block-statisctPort{
  width: auto;
  min-height: 100vh;
  background: #f1f8fdf7;
  left: 150px;
  position: absolute;
  color: black;
}
.map-modal {
  display: none;
  position: absolute;
  bottom: 2%;
  right: 1%;
  top: 2%;
  width: 820px;
  background-color: #e8f3f5;
  border-radius: 15px;
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  flex-direction: column;
  padding: 10px;
  z-index: 2;
}
@media (min-height: 864px) {
}
.loader-container {
  position: absolute;
  z-index: 3;

  position: absolute;
  top: 0;
  right: 0;
  left: 150px;
  /* width: 100%; */
  height: 100%;
}
</style>