<template>
	<div class="block-statisctYard p-3">
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
    <CCol sm="12" class="d-flex mb-2 px-0" style="justify-content: space-between;">
      <div>
        <h2 class="mb-0">{{ YardData.YardAlias ?? '' }}</h2>
      </div>
      <div style="cursor: pointer;" @click="$emit('Close')">
        <CIcon name="x"/>
      </div>
    </CCol>
    <CRow>
      <CCol sm="12" lg="6">
        <CRow>
          <CCol sm="12" lg="5" class="mb-2">
            <label class="mb-0 font-weight-500">{{ $t('label.Area') }} M2:</label> {{ YardData.SquareMeters ?? 0 }} <br/>
            <label class="mb-0 font-weight-500">{{ $t('label.capStatic') }}:</label> {{ YardData.StaticCapacity ?? 0 }} <br/>
            <label class="mb-0 font-weight-500">{{ $t('label.heights') }}:</label> {{ YardData.OperationalCapacity ?? 0 }} <br/>
            <label class="mb-0 font-weight-500">{{ $t('label.condition') }}:</label> {{ $i18n.locale=='en' ? YardData.YardStatusDsEn||'' : YardData.YardStatusDsEs||'' }} <br/>
            <label class="mb-0 font-weight-500">TOTAL TEUS:</label> {{ YardData.TeusCapacity ?? 0 }} <br/>
            <label class="mb-0 font-weight-500">{{ $t('label.TeusOccupied') }}:</label> {{ YardData.TeusOccupied ?? 0 }} <br/>
            <label class="mb-0 font-weight-500">{{ $t('label.TeusAvailable') }}:</label> {{ YardData.TeusAvailable ?? 0 }} <br/>
            <label class="mb-0 font-weight-500">{{ $t('label.LastReception') }}:</label> {{ getDate(YardData.LastReception) }} <br/>
            <label class="mb-0 font-weight-500">{{ $t('label.LastDispatch') }}:</label> {{ getDate(YardData.LastDispatch) }} <br/>
          </CCol>
          <CCol sm="12" lg="7">
            <CCol sm="12" class="mb-2 block-statistics">
              <img style="border-radius: 5px;" width="100%" height="196px" :src="YardData.YardProfileRoute ? this.$store.getters['connection/getBase']+YardData.YardProfileRoute : ''">
            </CCol>
          </CCol>

          <CCol sm="12" lg="12">
            <CRow>
              <CCol sm="12" lg="6" class="mb-2">
                <div class="row m-0" style="border-bottom: 1px solid rgb(68, 114, 196);">
                  <div class="title-row" style="width: 50%;">
                    <div class="block-title">
                      <b>{{ $t('label.ContainerEquipment')+'S' }}</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>20´</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>40´</b>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="table-yard">
                    <div style="width: 50%;" class="table-port">
                      <div v-for="Container in ContMovsBySize">
                        <label class="mb-1">{{ $i18n.locale=='en' ? Container.ContainerDsEn||'' : Container.ContainerDsEs||'' }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="block-20 px-1" v-for="Container in ContMovsBySize">
                        <label class="mb-1">{{ Container.Quantity20ft ?? 0 }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="px-1" v-for="Container in ContMovsBySize">
                        <label class="mb-1">{{ Container.Quantity40ft ?? 0 }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="6" class="mb-2">
                <div class="row m-0" style="border-bottom: 1px solid rgb(68, 114, 196);">
                  <div class="title-row" style="width: 50%;">
                    <div class="block-title">
                      <b>{{ $t('label.type') }}</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>20´</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>40´</b>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="table-yard">
                    <div style="width: 50%;" class="table-port">
                      <div v-for="TpCargo in TpCargoBySize">
                        <label class="mb-1">{{ TpCargo.TpCargoCode ?? '' }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="block-20 px-1" v-for="TpCargo in TpCargoBySize">
                        <label class="mb-1">{{ TpCargo.Quantity20ft ?? 0 }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="px-1" v-for="TpCargo in TpCargoBySize">
                        <label class="mb-1">{{ TpCargo.Quantity40ft ?? 0 }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>

              <CCol sm="12" lg="6" class="mb-2">
                <div class="row m-0" style="border-bottom: 1px solid rgb(68, 114, 196);">
                  <div class="title-row" style="width: 50%;">
                    <div class="block-title">
                      <b>{{ $t('label.newShippingLine') }}</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>20´</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>40´</b>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="table-yard">
                    <div style="width: 50%;" class="table-port">
                      <div v-for="ShippingLine in ShippingLineBySize">
                        <label class="mb-1">{{ ShippingLine.ShippingLineCode ?? '' }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="block-20 px-1" v-for="ShippingLine in ShippingLineBySize">
                        <label class="mb-1">{{ ShippingLine.Quantity20ft ?? 0 }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="px-1" v-for="ShippingLine in ShippingLineBySize">
                        <label class="mb-1">{{ ShippingLine.Quantity40ft ?? 0 }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
              
              <CCol sm="12" lg="6" class="mb-2">
                <div class="row m-0" style="border-bottom: 1px solid rgb(68, 114, 196);">
                  <div class="title-row" style="width: 50%;">
                    <div class="block-title">
                      <b>{{ $t('label.block') }}</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>20´</b>
                    </div>
                  </div>
                  <div class="title-20" style="width: 25%;">
                    <div class="block-title">
                      <b>40´</b>
                    </div>
                  </div>
                </div>
                <div class="pt-2">
                  <div class="table-yard">
                    <div style="width: 50%;" class="table-port">
                      <div v-for="Block in YardBlockBySize">
                        <label class="mb-1">{{ Block.BlockAlias ?? '' }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="block-20 px-1" v-for="Block in YardBlockBySize">
                        <label class="mb-1">{{ Block.Quantity20ft ?? 0 }}</label>
                      </div>
                    </div>
                    <div style="width: 25%;" class="table-port text-center">
                      <div class="px-1" v-for="Block in YardBlockBySize">
                        <label class="mb-1">{{ Block.Quantity40ft ?? 0 }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </CCol>

      <CCol sm="12" lg="6">
        <CRow>
          <CCol sm="12" lg="6">
            <div class="block-statistics">
              <div class="block-white p-1 text-center">
                <b class="text-white">{{ $t('label.YardTeus') }}</b>
                <div style="height: 167px">
                  <SimpleDonutOrPie
                    :Series="[TeusInYard?.OccupiedTeus??0, TeusInYard?.AvailableTeus??0]"
                    :Categories="[$t('label.Ocupados'), $t('label.Unoccupied')]"
                    :Colors="['#41aaf7f7', '#8fcbf7f7']"
                    :isDonut="false"
                    Width="100%"
                    Height="200px"
                  />
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="6">
            <div class="block-statistics">
              <div class="block-white p-1 text-center">
                <b class="text-white">{{ $t('label.ContainersInYard') }}</b>
                <div style="height: 167px">
                  <SimpleDonutOrPie
                    :Series="SeriesContainers"
                    :Categories="LabelsContainers"
                    :Colors="ColorsContainers"
                    :isDonut="true"
                    Width="100%"
                    Height="200px"
                  />
                </div>
              </div>
            </div>
          </CCol>
          <CCol sm="12" lg="12" class="mt-2">
            <div class="block-statistics">
              <div class="block-white p-1 text-center">
                <b class="text-white">{{ $t('label.newShippingLine') }}</b>
                <div>
                  <SimpleBar
                    :Series="SeriesShippingLine"
                    :Categories="LabelsShippingLine"
                    :Colors="ColorsShippingLine"
                    Width="100%"
                    Height="250px"
                  />
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
	</div>
</template>
<script>
import { mapState } from 'vuex';
import SimpleDonutOrPie from './simple-donut-or-pie';
import SimpleBar from './simple-bar';
import { DateFormater } from '@/_helpers/funciones';

function data() {
  return {
    Loading: false,
    YardData: {},
    ContMovsBySize: [],
    TpCargoBySize: [],
    ShippingLineBySize: [],
    YardBlockBySize: [],
    TeusInYard: {},
    ContInYard: {},
    ContByShippingLine: [],
  }
}

//methods
function YardSituation() {
  this.Loading = true;
  this.$http.get('YardSituation-by-YardId', { YardId: this.YardId})
    .then(response => {
      let YardSituation = response?.data?.data[0] ?? {};
      if (YardSituation?.YardDataJson&&YardSituation?.YardDataJson[0]) {
        this.YardData = YardSituation?.YardDataJson&&YardSituation?.YardDataJson[0] ? YardSituation.YardDataJson[0] : {};
        this.ContMovsBySize = YardSituation?.ContMovsBySizeJson ?? [];
        this.TpCargoBySize = YardSituation?.TpCargoBySizeJson ?? [];
        this.ShippingLineBySize = YardSituation?.ShippingLineBySizeJson ?? [];
        this.YardBlockBySize = YardSituation?.YardBlockBySizeJson ?? [];
        this.TeusInYard = YardSituation?.TeusInYardJson&&YardSituation?.TeusInYardJson[0] ? YardSituation?.TeusInYardJson[0] : {};
        this.ContInYard = YardSituation?.ContInYardJson&&YardSituation?.ContInYardJson[0] ? YardSituation?.ContInYardJson[0] : {};
        this.ContByShippingLine = YardSituation?.ContByShippingLineJson ?? [];
      }else{
        this.Reset();
        throw this.$t('label.YardNotConfiguationAssigned');
      }
    })
    .catch(err => {
      this.Reset();
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function getDate(Date) {
  if (Date) {
    return DateFormater.formatDateTimeWithSlash(Date);
  } else {
    return 'N/A';
  }
}

function Reset() {
  this.YardData = {};
  this.ContMovsBySize = [];
  this.TpCargoBySize = [];
  this.ShippingLineBySize = [];
  this.YardBlockBySize = [];
  this.TeusInYard = {};
  this.ContInYard = {};
  this.ContByShippingLine = [];
}

//computed
function SeriesContainers() {
  return this.ContInYard?.SizeJson?.map(Size => Size.Quantity);
}

function LabelsContainers() {
  return this.ContInYard?.SizeJson?.map(Size => Size.Size);
}

function ColorsContainers() {
  return this.ContInYard?.SizeJson?.map(Size => Size.SizeColor);
}

function SeriesShippingLine() {
  let data = [
    {
      name: '20´',
      data: this.ContByShippingLine.map(item => {
        return item?.SizeJson?.find(Size => Size.Size == 20)?.Quantity ?? 0;
      })
    },
    {
      name: '40´',
      data: this.ContByShippingLine.map(item => {
        return item?.SizeJson?.find(Size => Size.Size == 40)?.Quantity ?? 0;
      })
    },
    {
      name: '45´',
      data: this.ContByShippingLine.map(item => {
        return item?.SizeJson?.find(Size => Size.Size == 45)?.Quantity ?? 0;
      })
    },
  ];
  return data;
}

function LabelsShippingLine() {
  return this.ContByShippingLine.map(item => item.ShippingLineCode);
}

function ColorsShippingLine() {
  let Colors = [];
  this.ContByShippingLine[0]?.SizeJson?.forEach(item => {
    if(item.Size == 20){
      Colors[0] = item?.SizeColor ?? '';
    }else if(item.Size == 40){
      Colors[1] = item?.SizeColor ?? '';
    }else {
      Colors[2] = item?.SizeColor ?? '';
    }
  })
  return Colors;
}

export default {
  name:'yard-statistics',
  data,
  props: {
    FgPortStatics: {
      type: Boolean,
      default: false,
    },
    YardId: {
      type: String,
      default: () => '',
    }
  },
  components:{
    SimpleDonutOrPie,
    SimpleBar,
  },
  methods:{
    YardSituation,
    getDate,
    Reset,
  },
  computed:{
    SeriesContainers,
    LabelsContainers,
    ColorsContainers,
    SeriesShippingLine,
    LabelsShippingLine,
    ColorsShippingLine,
    ...mapState({
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
    })
  },
  watch: {
    FgPortStatics: function(NewVal) {
      if(NewVal){
        this.YardSituation();
      }else{
        this.Reset();
      }
    }
  }
}
</script>
<style lang="scss">
/* apexcharts -- quitar la opcion de descargar del componente */
.apexcharts-toolbar{
  display: none !important;
}
</style>
<style scoped>
.block-statisctYard{
  background: #f1f8fdf7;
  color: black;
  width: 100%;
  min-height: 100vh;
}
.font-weight-500{
  font-weight: 500;
}
.font-weight-400{
  font-weight: 400;
}
.text-underline{
  text-decoration: underline;
}
.block-Teus{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e5e5e5;
  border-radius: 5px;
}
.title-row{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title-20{
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-yard{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
}
.block-title{
  background: rgb(189, 215, 238);
  padding: 0.5rem;
}
.block-20{
  text-align: center;
  border-right: 1px solid rgb(68, 114, 196);
}
.block-statistics{
  background: #1A3760;
  padding: 0.15rem;
  border-radius: 5px;
}
.block-white{
  background: rgb(255, 255, 255, 0.2);
  border-radius: 5px;
}
.table-port div:last-child {
  border-right: none;
  font-weight: bolder;
}
</style>