<template>
    <section>
        <div class="location">{{ location }}</div>
        <div class="weather__description">{{ description }}</div>
        <img class="weather__icon" :src="icon" :alt="description">
    </section>
</template>

<script>
export default {
    name: 'Weather',

    props: {
        location: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #fff;
    margin-bottom: 10px;
}

.location {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
}

.weather__description {
    text-transform: lowercase;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    font-size: 12px;
}

.weather__description:first-letter {
    text-transform: uppercase;
}

.weather__icon {
    /* width: 12em;
    padding-bottom: 9em; */
    height: 3em;
}
</style>
